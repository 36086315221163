.mainContainer {
    height: 100%;
}
.mainsite-grid {
    height: 100%;
}
.mainsite-grid > * {
    padding: 0px !important;
    height: 100%;
}

.content
{
    overflow-y: scroll;
}
.main
{
    transition: margin-left .5s;
    padding: 16px;
    display: flex;
    overflow-y: scroll;
    flex-grow: 2;
}
.mainContainer
{
    display: flex;
    height: 100vh;
}

@media (max-width: 992px) { 
    .mainContainer
    {
        display: block;
        height: auto;
    }
    .content
    {
        overflow-y: auto;
    }
}